<header class="header">
  <div class="header__logo-box">
    <img src="assets/images/clean_logo.svg" alt="Logo" class="header__logo" />
  </div>

  <div class="header__attention__wrapper" *ngIf="today >= trigger">
    <div class="header__attention__content">
      <h2>VI ÄR NU EN DEL AV SMILE TANDVÅRD</h2>
      <div class="header__attention__content__lhs">
        <p>
          From 1. Januari 2025 är vi en del av Smile Tandvård Karlskrona och har
          flyttat in på Ronnebygatan 40 - endast 100 meter upp för backen. Trots
          nya lokaler och namn så möter du samma tandvårdsteam.
        </p>
        <p>
          Vi ser fram emot att fortsätta ta hand om dina tänder på Smile
          Tandvård Karlskrona.
        </p>
        <p>
          Har du frågor eller funderingar är du välkommen att kontakta oss på
          <a href="mailto:karlskrona@smile.se">karlskrona@smile.se</a> eller
          <a href="tel:0455-220 09">0455-220 09</a>
        </p>
      </div>
      <div class="header__attention__content__rhs">
        <img src="assets/images/smile.png" alt="smile logo" />
      </div>
    </div>
  </div>
  <div class="header__text-box">
    <h1 class="heading-primary">
      <span class="heading-primary--main">Tandläkare</span>
      <span class="heading-primary--sub">Josefine Hjalmarsson</span>
    </h1>
  </div>
</header>
