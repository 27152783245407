import { Component, OnInit } from "@angular/core";

@Component({
  selector: "home-section-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HomeHeaderComponent implements OnInit {
  today = new Date();
  trigger = new Date("2025-01-02");

  constructor() {}

  ngOnInit() {}
}
